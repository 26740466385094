export const GET_COURSES = "GET_COURSES";
export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_CHAPTERS = "GET_COURSE_CHAPTERS";
export const GET_COURSE_CHAPTER = "GET_COURSE_CHAPTER";
export const GET_COURSE_CHAPTER_WORDS = "GET_COURSE_CHAPTER_WORDS";
export const GET_COURSE_CHAPTER_WORD = "GET_COURSE_CHAPTER_WORD";
export const GET_COURSE_WORDS = "GET_COURSE_WORDS";
export const GET_COURSE_WORD = "GET_COURSE_WORD";
export const GET_CHAPTERS = "GET_CHAPTERS";
export const GET_CHAPTER = "GET_CHAPTER";
export const GET_CHAPTER_WORDS = "GET_CHAPTER_WORDS";
export const GET_CHAPTER_WORD = "GET_CHAPTER_WORD";
export const GET_WORDS = "GET_WORDS";
export const GET_WORD = "GET_WORD";
export const SET_LOADING = "SET_LOADING";
export const SET_IS_SEARCHING = "SET_IS_SEARCHING";
export const ACTION_UPDATE_DATABASE = "ACTION_UPDATE_DATABASE";
